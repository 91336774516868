@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.CardContainer{
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.CardCenter{
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 80px;
    display: flex;
    border-bottom: 1px solid black;
}
.cardImage{
    margin-right: 2rem;
    object-fit: contain;
}
.PostTime{
    font-size: 14px;
    margin-top: 9px;
    color: #999999;
}
.WrittenBy{
    font-size: 16px;
    margin-top: 5px;
    font-weight: bold;
    letter-spacing: 2px;
}
.CardContent{
    
    padding-bottom: 48px;
}
.BlogTitle{
    font-weight: bold;
    padding-bottom: 10px;
}
.CardDesc{
    padding-top: 20px;
    padding-bottom: 30px;
}

.ViewBtn{
    background: black;
    border-radius: 34px;
    color: white;
    width: fit-content;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    
}
.ViewLink{
    color: white;
    transition: 0.5s;
}
.ViewLink:hover{
    color: black;
}
.ViewBtn:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    color: black;
    background: rgb(229, 226, 226);
}

@media screen and (max-width: 950px){
    .CardCenter{
        margin-right: 20px;
        margin-left: 20px;
        flex-direction: column;
    }
}

