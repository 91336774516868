.filter-experts {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
  text-align: end;
  position: relative;
  cursor: pointer;
  right: 25px;
}
.experts {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px auto;
}

.dropdown-filter {
  background: rgba(217, 217, 217, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px;

  width: 20%;
  position: absolute;
  top: 2rem;
  right: 0;
  display: block;

  margin: 0 0 0 auto;
  z-index: 10;
}
.dropdown-filter .option {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  border-bottom: 0.5px solid #c6c6c6;
}
