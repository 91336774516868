.input_color {
  background: #f5f5f5 !important;
  border: none !important;
}
.input-lebal {
  color: #01bd4e !important;
}
.post-form {
  width: 90%;
}
