.activity{
    font-size:14px;
}
.subtype{
position: relative;
left: 5px;
/* font-size: 16px; */
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: rgba(0, 0, 0, 0.71);
font-size: 12px !important;
}
.type{
    color: black;
    max-width: 90% !important;
    font-size: 14px !important;
}

.time{
    /* margin-left: 69%;  */
    text-align: left;
    min-width: none;
}
.edit-modal{
    display: flex;
    justify-content: center;
    align-items: center;

}
.edit-modal .modals{
    height: max-content !important;
}
.edit-modal .modals input{
    margin: 8px;
    /* width: 100%; */
    border: none;
    border-radius: 4px;
}


