.profile-creation {
  display: grid;
  grid-template-columns: 28% 72%;
}
.imageBtn{
  background-color: #01bd4e;
  border: none;
  color: white;
  padding: 10px 20px;border-radius: 8px;
}
@media screen and (max-width: 1220px) {
  .profile-creation {
    grid-template-columns: 1fr 1fr;
  }
}

.profile-creation__side-img {
  height: 100vh;
  position: fixed;
  background: rgba(1, 189, 78, 0.4);
}
.profile-creation__side-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.65;
  color: transparent;
}

@media screen and (max-width: 620px) {
  .profile-creation {
    grid-template-columns: 1fr;
  }
  .profile-creation__side-img {
    display:none;
  }
}
.profile-creation__content {
  padding: 10px 15px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.profile-creation__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-basis: 100px;

  padding: 10px 0;
  border-bottom: 2px solid #c6c6c6;
  /* margin-bottom: 10px; */
}

.profile-creation__header-logo {
  height: 65px;
  margin-left: 10px;
}

.profile-creation__header a {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;

  color: #01bd4e;
}

.profile-creation__main {
  margin-top: 5px;
  width: 100%;
  flex: 10000;
}
/* ZERO */
.zero {
  width: 100%;
  position: relative;
}
.zero__title {
  font-weight: 400;
  font-size: 25px;
  line-height: 33px;
  height: 200px;
}
.zero__form {
  height: auto;
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.one{
  display: flex;
  flex-direction: column;
}
.zero__form-heading.one-option {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #01bd4e;

  margin-bottom: 10px;
}
.zero__linkedIn {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  width: 100%;
  padding: 10px auto !important;
  background: #01bd4e !important;
}

.zero__form-sub-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #4f4f4f;
  margin: 0 auto;

}

.zero__form__or {
  margin: 25px auto;
}

.zero__form__or .or {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4f4f4f;
}

.zero__form__or .left,
.zero__form__or .right {
  border: 2px solid #d9d9d9;
  width: 150px;
  display: inline-block;
}

.zero__form__or .or {
  margin: 0 10px;
}

.zero__enter-manually {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #01bd4e !important;
  width: 100%;
  padding: 10px auto !important;
  /* padding: 10px 55px !important; */
}

/* ----------ONE------------ */
.maxWidth80 {
  max-width: 83%;
}
.one {
  height: 80%;
}
.one__header {
  margin-bottom: 15px;
}
.one__form {
  height: 100%;
}
.one__header .title {
  font-weight: 400;
  font-size: 25px;
}
.one__header span {
  width: 104.49px;
  height: 5.4px;
  background: #f0f0f0;
  border-radius: 5px;
  display: inline-block;
}
@media screen and (max-width: 1220px) {
  .one__header span {
    width: 50px;
  }
}
@media screen and (max-width: 620px) {
  .one__header span {
    width: 32px;
  }
  zero__title{
    text-align: center;
    font-size: 18px;
  }
}
.one__header span:not(:first-child) {
  margin-right: 10px;
}

.one__form-heading {
  margin-bottom: 10px;
}
.one__form__input-field {
  margin-bottom: 15px;
}
.one__form__input-field input {
  width: 100%;
  background: #f5f5f5;
  outline: none;
  padding: 8px;
  border: none;
  border-radius: 5px;
}
.one__form__input-field .select-input {
  background: #f5f5f5;
  /* color: #f5f5f5; */
}
.one__form__input-field .select-input option {
  background: #f5f5f5;
  color: #4f4f4f;
  padding: 4px 0;
}
.one__form-Enter-intro input {
  padding: 8px 8 180px 8px;
  position: relative;
}
.one__form-Enter-intro input::-webkit-input-placeholder {
  position: absolute;
  top: 8px;
  left: 8px;
}
.one__form-input-caption {
  text-align: end;
}
.one__form-heading {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
}
.one__form-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #01bd4e;
  margin-bottom: 8px;
}
.one__footer {
  border-top: 2px solid #d9d9d9;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.one__footer .footer-btns {
  padding: 10px 20px;
  margin: 10px;
}
.one__footer .footer-btns.back {
  padding-left: 20px;
  padding-right: 20px;
}

.one__footer .btn-primary:hover,
.one__footer .btn-primary:active {
  background-color: #01bd4e !important;
}

.one__footer .btn-outline {
  color: #4f4f4f;
}

/* -----------------TWO------------ */
.one__header span.bg-green {
  background: #01bd4e;
}
.one__form__input-field.date {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.one__form__input-field.date div{
  flex-direction: row;
}
.one__form__input-field .from,
.one__form__input-field .to {
  display: flex;
}
.one__form-role-and-company {
  display: flex;
  justify-content: space-between;
}
.one__form__input-field.role,
.one__form__input-field.company {
  flex-basis: 49%;
}
.one__form-label.from {
  flex-basis: 2;
}
.one__form-label.to {
  flex-basis: 2;
}

.one__form__input-field.date {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.one__form__input-field .from:nth-of-type(2),
.one__form__input-field .to:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.one__form__input-field.date input {
}

.one__form-currentlly-working .currently-working {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4f4f4f;
  display: inline-block;
}
.one__form-currently-working {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.one__form__input-field.date .one__form-currently-working [type="checkbox"] {
  border: none;
  outline: none;
  background: #f5f5f5;
  height: 19px;
  width: 19px;
  margin-right: 10px;
}

.add-work-experience-btn,
.add-education-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* background: #01bd4e; */
  border: 1.5px solid #01bd4e;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  width: 309px;
  margin: 50px 0 0 0;
  color: #01bd4e;
}
.add-work-experience-btn:hover,
.add-education-btn:hover {
  color: #096d33;
}
/* FOur */

.one__form-Enter-intro.skills input {
  padding: 8px 8px 100px 8px;
}
