.our-experts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}

.experts-heading {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
}
