.contact-form {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;
    border-radius: 10px;
}

.contact-form .input {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 80%;

}

.footer-dv {
    display: flex;
    justify-content: space-between;

}

.input-group {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .input-group {
        flex-direction: column;
    }

    .input-group input {
        width: 100% !important;
    }
}

.contact-form .input a {
    height: 100%;
    width: 205px;
    border-radius: 10px;
}

.contact-form .input {
    margin: 0;
    padding: 0;
}