.trending {
  margin-top: 0;
  text-align: center;
}
.trending .heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  color: #000000;
  padding: 0;
  margin-bottom: 30px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

    