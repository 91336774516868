@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
.text {
  font-family: "Playfair Display";
}
.align-head {
  padding: 30px;
  text-align: center;
  font-weight: 600;
}
.sidebar {
  
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 294px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  background: #01c853;
  overflow:scroll;
  /* border-radius: 10px; */
}
.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar__logo {
  position: absolute;
  width: 142px;
  /* height: 100%; */
  left: 65px;
  top: 0px;
}

.sidebar__heading {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 33px;
  color: #ffffff;
  margin-top: 115px;
}

.sidebar__nav {
  flex: 3;
}

.sidebar__nav a {
  text-decoration: none;
  text-align: center;
  display: block;
  padding: 12px 2rem;
  background: transparent;
  border-radius: 25px;
  margin: 20px auto;
  width: 100%;
  display: flex;
  /* justify-content:space-around; */
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  color: #ffffff;
  transition: transform 0.2s ease-out;
}.sidebar__nav a span{
  margin-right:20px
}
.sidebar__nav a:hover {
  text-decoration: none;
  color: #01bd4e;
  background: #ffffff;
  transform: scale(1.05);
}
.active {
  background-color: orangered;
  color: black;
}

.layout__main {
  margin-left: 284px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 1.6rem 2rem; */
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid #c6c6c6;
}

.search__box {
  position: relative;
  margin-left: 1rem;
}

.search__logo {
  position: absolute;
  left: 1rem;
  top: 12px;
  height: 25px;
  margin-right: 10px;
}

.search__box-input {
  border: none;
  background: rgba(217, 217, 217, 0.27);
  border-radius: 25px;
  padding: 10px 60px 10px 55px;
}
.search__box-input::placeholder {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.search__box-input:focus {
  border: none;
}
.admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.admin .text {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
}
@media screen and (max-width: 700px) {
  .layout__main {
    margin-left: 0rem;
  }
  .blogs__list{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .hidden-sidebar{
    display:none;
  }
}