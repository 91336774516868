.card-of-expert {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(79, 79, 79, 0.3);
  border-radius: 10px;
  width: 372px;
  height: 170px;
  margin: 25px;
}
.card-of-expert .image {
  height: 100%;
  width: 40%;
  background-size: cover;
  background-position: center;
  flex-basis: 40%;
}
.card-of-expert .content {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
}
.card-of-expert .content .title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 21px;
  color: #000000;
}

.card-of-expert .content .subtitle {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* grey */

  color: #4f4f4f;
}
