.backdrop {

  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  /* background: orangered; */
}

.backdrop .modals {
  /* position: fixed; */
  width: 400px;
  /* height: 174px; */
  /* left: 580px; */
  /* top: 150px; */

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  z-index: 100;
  overflow: hidden;

  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
}

.close {
  align-self: flex-end;
  margin-right: 10px;
}

.tick {
  display: block;
  height: 30px;
  margin: 10px auto;
}

.modal .title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #01bd4e;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.view-btn {
  background: #0041be;
  border-radius: 15px;
  padding: 5px 1rem;
  margin: 10px auto;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
  margin: 10px 2rem;
  width: 100%;
}
.modal-actions .btns {
  color: #ffffff;
  width: 81px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 0, 15, 0.91);
  border-radius: 15px;
  cursor: pointer;
}

.modal-actions .btns.no {
  background: #01bd4e;
  cursor: pointer;
}

.modals .input-field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal .input-label {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 33px;
  color: #000000;
}
.modal .input-field input,
.modal .input-field input:focus {
  outline: none;
  border: none;
}

.input-field.content {
  display: flex;
}

.input-field.content input {
  padding: 8px 100px 325px 8px;
  display: block;
  flex: 2;
  background: rgba(217, 217, 217, 0.37);
}

.edit-modal.backdrop .modals.editForm {
  width: 706px !important;
  /* height: 555px !important; */
  /* left: 400px;
  top: 25px; */
  padding: 14px;
}

.create.btn {
  background: #01c853;
}
