@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');
.centerContent{
  padding-left: 300px;
  padding-right: 300px;
  padding-top: 100px;
  font-family: 'Roboto Slab', serif;
}
.name1{
  font-size: 17px;
  color: #575454;
}
.date1{
  font-size: 17px;
  color: #575454;
}
.Title .myTitle{
  font-size: 29px;
  width: 907px;
  font-weight: bold;
}
.smallTitle{
  font-size: 17px;
  padding-top: 13px;
  color: #2b2a2a;
  padding-bottom: 50px;
}
.Image{
  text-align: center;
  padding-top: 31px;
}
.Image img{
  width: 900px;
}
.description h5{
  color: #5e5e5e;
  font-weight: bold;
  padding-bottom: 50px;
}


@media screen and (max-width: 900px){
  .centerContent{
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 700px){
  .centerContent{
    padding-left: 20px;
    padding-right: 20px;
  }
}



/* .single-blog h3 {
  margin-top: 30px;
}
.single-blog {
  text-align: center;
  padding-top: 0;
}
.posted-blog{
  padding: 30px;
  word-wrap: break-word;
}
.single-blog .title {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 67px;

  color: #ffffff;
  position: absolute;
  width: 892px;
  height: 134px;
  left: 229.73px;
  top: 189.5px;
}
.single-blog a {
  margin: 30px;
  margin-top: 50px;
}

.blogLandingImageWrapper {
  margin-bottom: 18px;
  width: 100%;

  background-image: linear-gradient(
    to right,
    rgba(126, 213, 111, 0.8),
    rgba(40, 180, 133, 0.8)
  );
}

.blogLandingImageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */