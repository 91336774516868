@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

:root {
  --primary-color: #00c853;
  --secondary-color: #64dd17;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: "Playfair Display", sans-serif;
  width: 100vw;
  overflow-x: hidden;
}
section {
  padding-top: 80px;
  padding-bottom: 80px;
}
ul {
  list-style: none;
}
a {
  color: #4f4f4f;
}
p {
  font-size: 1.4rem;
}
a:hover {
  text-decoration: none;
}

/* Reusable classes */
.display-5 {
  font-size: 1rem !important;
}
.no-pad {
  padding: 0px;
}
.primary {
  color: var(--primary-color);
}
.bg-first {
  background-color: var(--secondary-color) !important;
}
.bg-offwhite {
  background-color: rgb(112, 112, 112);
}
.no-mar {
  margin: 0px !important;
}
.container {
  max-width: 1100px;
  margin: 0 auto;
}
.card {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  margin: 10px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.btn {
  display: inline-block;
  background: #01bd4e;
  color: #fff;
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: "Playfair Display";
}
.btn:hover {
  transform: scale(0.98);
}
.btn-outline {
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.button-primary {
  font-size: 20px;
  padding: 10px 40px !important;
  color: white;
  background-image: linear-gradient(to right, #00c853 0%, #64dd17 100%);
  border: 0px;
}


.button-primary:hover {
  font-size: 20px;
  padding: 10px 40px !important;
  color: white;
}


#app {
  margin: 0px 0px !important;
  background-color: white;
}
.logo-main {
  font-size: 5rem !important;
}

/***Nav Bar***/

/**HomePage***/
.home-page {
  text-align: center;
  min-height: 350px;
}

/**Register Page **/
.register {
  margin: 20px auto;
  text-align: center;
}
.register-form {
  width: 60%;
  margin: 30px auto;
}
/* .register-form input {
  margin: 15px;
} */

.register .btn:focus {
  border: none;
}

/****login***/
.login {
  margin: 20px auto;
  text-align: center;
}
.login-header {
  text-transform: uppercase;
}
.login-form {
  margin: 30px auto;
  width: 50%;
}
.login-btn {
  min-width: 100px;
}
.login .btn-group {
  width: 50%;
  max-width: 500px;
}

.login-link:hover,
.login-link:visited {
  color: black;
  text-decoration: none;
}

/***Business Problem***/
.business-post-problem {
  margin: 20px auto;
  width: 50%;
}

.post-form .input-group {
  width: 100%;
  margin: 30px auto;
  /* height: 300px; */
}
.post-form {
  margin: 30px auto;
  text-align: center;
}
.post-form button {
  margin: 20px;
  width: 30%;
}
.form-group {
  margin-top: 15px;
  text-align: left;
}

.business-dashboard {
  margin: auto;
  padding: 2% 5%;
  position: relative;
}
.business-flex {
  display: flex;
  justify-content: space-between;

  flex-direction: row;
  position: relative;
}
.business-dashboard-row {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: flex-start;
}
.business-flex .btn {
  margin-top: 15px;
}
/* 
.card-header,
.card-footer {
  background-color: #c6caca;
  border: 0;
}
.card-header {
  padding-bottom: 1%;
  font-size: large;
  font-weight: bold;
  text-transform: uppercase;
}
.card-footer {
  display: flex;
  padding: 1% 30%;
  justify-content: space-around;
  text-decoration: underline;
}
.card:last-child {
  margin-bottom: 5%;
}
.card-link,
.card-link:hover {
  color: black;
  text-decoration: underline;
  text-transform: uppercase;
}
.card-body {
  border: 0;
  padding-right: 10%;
  padding-top: 0%;
}
.card-head {
  display: flex;
  justify-content: start;
  margin: 15px 0;
  font-weight: bold;
}
.card-head:first-child {
  margin-top: 5px;
}
.card-head h6 {
  margin: 0;
  font-weight: bold;
}
.card-btn-grp {
  position: absolute;
  right: 0;
}
.card-btn-grp {
  padding: 0;
}
.card-btn-grp li {
  padding: 0;
  margin: 0;
  position: relative;
}
.card-btn-grp li:hover button {
  opacity: 1;
}
.card-btn-grp li button {
  padding: 0;
  position: absolute;
  right: -50px;
  opacity: 0;
}
.card-head p {
  margin: 0;
  font-weight: normal;
} */

.business-dashboard-btn-grp {
  /* position: absolute;
  align-items: center;
  top: -29px;
  right: 200px; */
  display: inline-flex;
  background-color: white;
}
.business-dashboard-btn-grp .btn {
  width: 100;
  margin-left: 15%;
}

/** Modal **/

.box {
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  margin: 12px 0;
}
.box p{
  font-size: 12px;

}
.my-ul {
  list-style: none;
}
/* @media screen and (min-width: 768px) {
  #businessmodal .modal-dialog {
    width: 900px;
  }
} */
#businessmodal {
  /* top: 15%; */
  /* left: -23%; */
  width: 100vw;
  outline: none;
  /* overflow-y: hidden; */
}
#businessmodal .modal-dialog {
  max-width: 85%;
  max-width: 1000px;

}
#businessmodal .modal-content {
  background-color: #eeeeee;
}
#businessmodal .close {
  margin: 0 !important;
}
/****Pagination **/
.pagination {
  margin-top: 10px;
}
/** chat box */
#chatModal {
  top: 10%;
  left: 25%;
}
#chatModal .modal-body {
  height: 40%;
}
#chatModal .msg-area {
  height: 350px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.sender {
  margin-right: auto;
}
.reciever {
  margin-left: auto;
}

.msg-area .btn-grp {
  margin: 15px auto;
}
.dash-icon img{
  height:30px;
  width:30px;
}
/*** Consultant Page ***/
.consultant-dashboard {
  margin: auto;
  padding: 2% 5%;
  position: relative;
}
.consultant-flex {
  display: flex;
  justify-content: space-between;
}
.consultant-dashboard-row {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: flex-start;
}
.consultant-dashboard-row .btn-grp {
  display: flex;
  justify-content: start;
  flex-direction: row;
}
.consultant-dashboard-row button {
  margin: 0 15px;
}
.inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /*Using a background color, but you can use a background image to represent a button*/
  background-color: #ddf;
}
.commingSoon{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
}
.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /*This makes the button huge so that it can be clicked on*/
  font-size: 50px;
}
.hidden {
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.card-body{
  word-break: break-all !important;
}
.socini {
  background: rgb(98, 165, 177);
  background: -moz-linear-gradient(
    90deg,
    rgba(98, 165, 177, 1) 4%,
    rgba(46, 124, 168, 1) 69%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(98, 165, 177, 1) 4%,
    rgba(46, 124, 168, 1) 69%
  );
  background: linear-gradient(
    90deg,
    rgba(98, 165, 177, 1) 4%,
    rgba(46, 124, 168, 1) 69%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#62a5b1",endColorstr="#2e7ca8",GradientType=1);
}

.carousel-item {
  height: 600px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  background-color: #cccccc;
}
.carousel-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.mainimgbox {
  position: absolute;
  top: 200px;
  left: -94px;
  max-width: 161px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  background: rgba(1, 189, 78, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-left: 95%;
}
.padding-0 {
  padding-right: 25px;
  padding-left: 0px;
}
.mbtns {
  margin-top: 8%;
}
.shiftuphowwework {
  margin-top: -5%;
}
.catl2 {
  margin-top: 5%;
}
.justify {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: #4f4f4f;

  margin-top: -2%;
}
.jumbotron {
  background-color: transparent !important;
}
.aboutus {
  margin-top: -4%;
}
.abouttext {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.proj2 {
  margin-top: 2%;
}
.proj {
  margin-left: 3%;
}
.expl2 {
  margin-top: 0%;
}
.nobl {
  list-style-type: circle;
}
.howwework {
  background-color: rgb(239, 255, 255);
}
.circle {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0px;
  background: #fff;
  border: 0px solid #000;
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}
.expborder {
  box-sizing: border-box;
  border: 1px solid rgba(79, 79, 79, 0.3);
  border-radius: 10px;
}
.catsquare {
  height: 50px;
  width: 50px;
  background-color: rgb(239, 255, 255);
}
.contactmargin {
  margin-top: -1.5%;
  margin-bottom: 1%;
}
.createProjectbutton {
  margin-top: 10%;
  margin-left: 0%;
  max-width: 300px;
}
.contacttext {
  margin-left: 14%;
  margin-right: 14%;
  margin-top: 4%;
}
.btn.btn-square {
  background: #01bd4e;
}

.footer-bg {
  background-color: #01c853;
}
.footer-logo {
  display: block;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  width: 18%;
}
.footer-img {
  max-width: 100%;
  height: auto;
}
.textleft {
  text-align: left;
  font-family: "Playfair Display";
}
.social {

}
.copyright {
  margin-top: -1%;
  color: #4f4f4f;
  font-family: "Playfair Display";
}
