.navbar {
  position: sticky;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  margin: 0;
}

.navbar img {
  width: 75px;
  margin: auto 20px;
}

.navbar ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar ul li {
  margin: auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar ul li:hover {
  border-bottom: 4px solid #01bd4e;
}
.navbar ul li a {
  text-decoration: none;
  color: #4f4f4f;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

.navbar .buttons {
  display: flex;
  margin-right: 30px;
}
.navbar .buttons a {
  padding: 5px 10px;
}

/* Skills navigation */
.skills-nav {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  height: 82.75px;
  background: rgba(1, 200, 83, 0.1);
}

.skills-nav div {
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
  margin: auto 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills-nav a {
  /* padding: auto 10px; */
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.skills-nav a:hover {
  text-decoration: none;
  color: #4f4f4f;
}
.skills-nav a.active {
  background-color: #fff;
}
