.CreateBlogCenter{
    margin: auto;
    width: fit-content;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.CreateBlogHeading{
    background: #00c853;
    padding-top: 25px;
    color: white;
    padding-bottom: 25px;
    text-align: center;
}
.CreateBlogContent{
    padding-left: 70px;
    padding-right: 70px;
}
.CreateBlogTitle, .BlogDesc, .BlogContents{
    width: 532px;
    padding: 12px;
    border: none;
    border-bottom: 0.5px solid black;
    font-size: 21px;
    margin-top: 20px;
    background: white;
    color: black;
    margin-bottom: 20px;
}
.BlogImageU{
    font-size: 16px;
    background: white;
    border-radius: 20px;
    width: 350px;
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
::-webkit-file-upload-button{
    color: white;
    background: #00c853;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.5s;
    outline: none;
}
::-webkit-file-upload-button:hover{
    background: #438a5e;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.BlogBtn{
    text-align: center;
    margin: auto;
}
.Bbtn{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 70px;
    padding-right: 70px;
    width: fit-content;
    margin-bottom: 20px;
    background: #00c853;
    border: none;
    border-radius: 40px;
    font-size: 18px;
    margin-top: 30px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: 0.6s;
}
.CreateBlogLength{
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size:14px;
}
.Bbtn:hover{
    background: #1c8247;
    color: white;
}

@media screen and (max-width: 900px) {
    .CreateBlogCenter{
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media screen and (max-width: 700px) {
    .CreateBlogCenter{
        padding-left: 0px;
        padding-right: 0px;
    }
}