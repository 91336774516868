.landing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 0;
  /* align-items: center; */
}
.landing .content {
  margin-left: 30px;
}
.content .title {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  /* line-height: 80px; */
  color: #01bd4e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 1220px) {
  .content .title {
    /* line-height: auto; */
    font-size: 50px;
  }
}
@media screen and (max-width: 620px) {
  .content .title {
    /* line-height: auto; */
    font-size: 32px;
  }
  .landing {
    grid-template-columns: 1fr;
  }
}
.landing .para {
  font-weight: 400;
  font-size: 18px;
  /* line-height: 24px; */
  color: #4f4f4f;
  margin-top: 20px;
}

.landing .buttons {
  margin-top: 30px;
}
.profile {
  padding: 16px;
  gap: 10px;
  margin: 10px;
  z-index: 1000;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 28px; */
  color: #4f4f4f;

  background: rgba(1, 189, 78, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.profile .field {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 500;
}
.profile .exp {
  font-size: 14px;
}
.main-img{
  display: flex;
}
.main-img img {
  
  margin-top: 0;
  margin-left: 20px;
  height: 80%;
  min-width: 40vw;
}
