@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
.expert-card {
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 7px 5px;
  width: 242px;
  min-height: 374px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
  font-family: 'Roboto Slab', serif;
}

.expert-card .img-container {
  border-radius: 5px;
  height: 200px;
  width: 200px;
  align-items: center;
  justify-content: center;
}

.expert-card .img-container img {
  height: 100%;
}

.expert-card .expert-name {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #000000;
  margin-bottom: 5px;
}

.expert-card .experience {
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  /* green */

  color: #01bd4e;
}

.expert-card .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  /* grey */

  color: #4f4f4f;
}





.expcards {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  max-width: 820px;
}

/* .expcard--1 .expcard__img, .expcard--1 .expcard__img--hover {
  background-image: url('https://images.pexels.com/photos/45202/brownie-dessert-cake-sweet-45202.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
} */



.expcard__img {
visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 235px;
border-top-left-radius: 12px;
border-top-right-radius: 12px;

}

.expcard__info-hover {
  position: absolute;
  padding: 16px;
width: 100%;
opacity: 0;
top: 0;
}

.expcard__img--hover {
transition: 0.2s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
position: absolute;
  height: 235px;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
top: 0;

}
.expcard {
margin-right: 25px;
transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
background-color: #fff;
  /* width: 33.3%; */
  width: 100%;
position: relative;
border-radius: 12px;
overflow: hidden;
box-shadow: 0px 13px 10px -7px rgba(0, 0, 0,0.1);
}
.expcard:hover {
box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
  transform: scale(1.10, 1.10);
}

.expcard__info {
z-index: 2;
background-color: #fff;
border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
 padding: 16px 24px 24px 24px;
}

.expcard__category {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
color: #868686;
}

.expcard__title {
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: 'Roboto Slab', serif;
}

.expcard__by {
  font-size: 12px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.expcard__author {
  font-weight: 600;
  text-decoration: none;
  color: #AD7D52;
}

.expcard:hover .expcard__img--hover {
  height: 100%;
  opacity: 0.3;
}

.expcard:hover .expcard__info {
  background-color: transparent;
  position: relative;
}

.expcard:hover .expcard__info-hover {
  opacity: 1;
}
