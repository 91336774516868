.cb__heading {
  font-weight: 500;
  font-size: 40px;
  line-height: 80px;
  color: #01bd4e;
  text-align: center;
}
.cb__form-container {
  max-width: 1000px;
  margin: 1rem auto;
  padding: 1rem;
}
.cb__input-field {
  margin: 1.5rem auto;
  width: 70%;
}
.cb__input-field input {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px;
  border: none;
  width: 100%;
}
.cb__input-field input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  color: #4f4f4f;
}
.cb__input-field .label {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #01bd4e;
  margin-bottom: 10px;
}
.cb__input-field.publish {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cb__publish-btn {
  width: 50%;
  margin: 5px auto;
  text-align: center;
  border-radius: 5px;
  border: none;
  background: var(--primary-color);
  padding: 10px 60px;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.status {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  
  color: #4f4f4f;
  text-align: center;
}
