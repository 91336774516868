.register__layout {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media screen and (max-width: 1220px) {
  .register__layout {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 620px) {
  .register__layout {
    grid-template-columns: 1fr;
  }
}
.register__showcase {
  height: 100vh;
  position: fixed;
  background: rgba(1, 189, 78, 0.4);
}
@media screen and (max-width: 600px) {
  .register__showcase {
    position:unset;
  }
}
.register__showcase-image {
  height: 100%;
  width: auto;
}
.register__showcase-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  opacity: 0.6;
  color: transparent;
}
.register__showcase-text {
  position: absolute;
  width: 299.25px;
  height: 335px;
  left: 62px;
  top: 170.55px;
  font-weight: 500;
  font-size: 50px;
  line-height: 67px;

  color: #ffffff;
}
.register__content-header {
  padding: 10px 20px;
  display: flex;

  
  justify-content: space-between;
  margin-bottom: 1px solid #c6c6c6;
  margin-top: 20px;
}

.register__content-heading {
  font-weight: 100;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  margin-left: 1.5rem;
  margin-top: 20px;
  color: #000000;
}

.register__content-form {
  margin: auto auto;
}

.register__content-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register__content-logo img {
  width: 75px;
  margin: auto 20px;
}

.register__content .btn {
  align-self: center;

  position: absolute;
  width: 220px;
  height: 30px;
  left: 1109.16px;
  top: 43px;

  background: #ffffff;
  border: 1px solid #01bd4e;
  border-radius: 5px;
  transition: transform 0.2s ease-out;
}
.register__content .btn:hover {
  color: #01bd4e;
  transform: scale(1.05);
}
.register__content-form {
  height: 100%;
}
.register__form-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-1rem);
}
.register__content-form {
  width: 50%;
}
@media screen and (max-width: 468px) {
  .register__content-form {
    width: 90%;
  }
}
