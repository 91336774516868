.allBlogs{
  padding-top: 50px;
}
.IMG{
  padding-left: 150px;
  padding-right: 170px;
  padding-bottom: 40px;
  border-bottom-left-radius: 257px;
  border-bottom-right-radius: 257px;
}
@media screen and (max-width: 900px){
  .IMG{
      padding-left: 20px;
      padding-right: 40px;
      border-bottom-left-radius: 150px;
      border-bottom-right-radius: 150px;
      
  }
}
/* @media screen and (max-width: 700px){

}
@media screen and (max-width: 400px){

} */