.contactus-inputfrom-div{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.contactus-inputfrom-div div{
    display:flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 40%;

}
.contactus-inputfrom-div input{
    width:100%;
}
.contact-form label{

    margin-top: 20px;
}
.contact-form input{
    height: 40px;
    border-radius:16px;
    border: 2px solid  #01BD4E;
    padding:10px;
}
.contact-form  textarea{
    border-radius:16px;
    width: 100%;
    border: 2px solid  #01BD4E;
    padding:10px;

}
.input-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.input-div input{
    width: 100%;
}
